import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Get notifications for and take action on specific incoming or outgoing messages.`}</p>
    <p>{`Using `}<strong parentName="p">{`Alerts`}</strong>{`, you can identify key actions in conversations you’d like to be notified of so that you could respond to them in a timely manner.`}</p>
    <p>{`Whether a user sends a message that says “Unsubscribe” or your bot sends a message that says “I do not understand,” you will be alerted of the occurrence via email or webhook and can jump into the conversation directly.`}</p>
    <h2>{`Follow the steps below to set up Alerts:`}</h2>
    <p>{`1`}{`.`}{` When you log in to your Dashbot account, you’ll find Alerts on the left navigation bar below Actions.`}</p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2017/09/06213837/c0a4e-1dw9cebs5sepsrqv7xzgsiq-1.png)
    </figure>
2\. Choose which messages you’d like notifications for: incoming, outgoing, or
both.
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2017/09/06213836/c84cf-18lvcgad-sjpqovcrbyxqmg.png)
    </figure>
3\. Select the message type: text, regular expression, postback, or sentiment
value.
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2017/09/06213835/6f49d-1mbvvbwg3wwofhqvsuk3gbq.png)
    </figure>
4\. Lastly and most importantly, choose where you’d like to receive your alert —
via email or webhook. While the details of the implementation may slightly vary,
your webhook should track your alerts.
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2017/09/06213835/79638-1c6qryb9yvxdeyzoqh8qlww.png)
    </figure>
*Quick Tip:* For a webhook, whenever the trigger is fired, it will send a
**POST** to your webhook with a payload that would look something like this:
    <pre><code parentName="pre" {...{}}>{`{
  "trigger_name": "Unsubscribe",
  "user_id": "733797576500088832",
  "channel": "733797576500088832",
  "text": "unsubscribe",
  "timestamp": "09/08/2017 22:31 +00:00",
  "bot_id": "2484",
  "transcript_url": "https://www.dashbot.io/live/session/2484/733797576500088832"
}
`}</code></pre>
    <h2>{`Check out the tutorial video:`}</h2>
    <iframe allow="autoplay; encrypted-media" allowFullScreen="" frameBorder="0" height="900" src="https://www.youtube.com/embed/cviy77bsBvw?feature=oembed" width="1200"></iframe>
    <hr></hr>
    <h2>{`About Dashbot`}</h2>
    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io/"
      }}>{`Dashbot`}</a>{` is a bot analytics platform that enables developers and brands to increase engagement, acquisition, and monetization through actionable data and tools.`}</p>
    <p>{`You can view detailed analytics `}{`&`}{` competitive market metrics, analyze conversation transcripts, track message funnels, optimize referral campaigns, understand sentiment `}{`&`}{` demographics, and add human-in-the-loop to your bot — all in real time.`}</p>
    <p>{`We natively support Facebook Messenger, Twitter, Amazon Alexa, Google Home, Slack, and Kik and have a generic API for any conversational interface. Sign up for free at `}<a parentName="p" {...{
        "href": "https://www.dashbot.io"
      }}>{`www.dashbot.io`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      